@import './variables/variables.scss';

.book-details-container {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* height: 100vh; */
    max-width: 1665px;
    margin: auto;
    padding: 0 20px 20px;
}

.book-details-container__inner {
    align-items: flex-start;
}

.book-card {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    width: 70%;
    /* max-width: 1200px; */
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px $boxShadow;
    padding: 65px;
    position: relative;
    min-width: 650px;
}

.book-thumbnail {
    max-width: 350px;
    width: 100%;
    object-fit: cover;
}

.book-card .book-thumbnail {
    margin-right: 50px;
}

.book-thumbnail img {
    max-height: 600px;
    width: 100%;
    margin-bottom: 20px;
}

.book-title {
    font-size: 2rem;
    margin: 0;
}

.book-card .book-authors {
    margin-bottom: 15px;
}

/* .book-details .book-title {
    margin-bottom: 20px;
} */

.book-card .details__additional-info {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.book-card .details__additional-info .book__action-area {
    margin-right: 15px;
}

.book-category {
    margin-top: 10px;
    font-style: italic;
}

.book-description {
    margin-top: 20px;
    font-size: 1rem;
    /* overflow-y: auto; */
    padding: 10px;
    max-width: 900px;
    width: 100%;
    margin-bottom: 20px;
    word-break: break-all;
}

.edit-book__icon {
    position: absolute;
    top: 30px;
    right: 60px;
    width: 25px;
    height: 25px;
    fill: $black;
    cursor: pointer;
    transition: fill ease-in-out 0.3s;
}

.book-card .modal__delete-btn {
    left: auto;
    right: 25px;
}

.edit-book__icon:hover,
.modal__delete-btn:hover {
    fill: $white;
}

.notes__container {
    margin: 0 20px;
    padding: 20px;
    width: 30%;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 2px 4px $boxShadow;
}

.notes__message-container {
    max-height: 400px;
    height: 100%;
    overflow-y: auto;
    margin-bottom: 20px;
}

.notes__create-button:hover {
    background-color: #0056b3;
}

.notes__add-form {
    display: flex;
    padding: 10px;
}

.notes__add-form .cta-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 10px;
}

.notes__add-form .cta-btn svg {
    width: 25px;
    height: 25px;
}

.notes__add-form textarea {
    margin-right: 20px;
}

.notes__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notes__inner svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.notes__add-label {
    font-weight: bold;
}

.notes__add-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    resize: vertical;
    /* margin-bottom: 10px; */
    resize: none;
}

.notes__add-button {
    margin-top: 10px;
    background-color: #007BFF;
    color: $white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.notes__add-button:hover {
    background-color: #0056b3;
}

.notes__header {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center;
}

.notes__list-container {
    margin-bottom: 20px;
    padding: 0 10px 10px;
    /* max-height: 525px; */
    /* overflow: auto; */
}

.notes__list {
    padding: 0;
}

.notes__item {
    border: 1px solid $lightGrey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    word-break: break-word;
}

.notes-item__container {
    margin: 0 auto 0 0;
    padding: 0 10px;
}

.notes-infinite-scroll {
    height: auto !important;
}

.note__actions {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 0;
}

.note__actions svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.edit-note,
.save-edit {
    margin-right: 5px;
}

.edit-note-textarea {
    width: 75%;
    resize: none;
}


@media (max-width: 991px) {
    .book-details-container>div:first-of-type {
        display: flex;
        align-items: center;

        flex-direction: column;
    }

    .notes__container {
        max-width: unset;
        margin: 40px 0;
    }

    .notes__item {
        max-width: 650px;
        margin: 0 auto 10px;
    }
}

@media (max-width: 768px) {
    .book-card {
        min-width: unset;
    }

    .book-card {
        flex-direction: column;
        width: 100%;
    }

    .book-card .book-thumbnail {
        margin-right: 0;
    }

    .notes__container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .book-details-container {
        padding-bottom: 100px;
    }

    .book-card .modal__delete-btn,
    .book-card .edit-book__icon {
        left: auto;
        top: 28px;
        width: 25px;
        height: 25px;
        right: 55px;
    }

    .book-card .modal__delete-btn {
        right: 20px;
    }

    .modal__delete-btn,
    .edit-book__icon {
        right: 8px;
        top: 40px;
        width: 20px;
        height: 20px;
    }

    .modal__delete-btn {
        left: 20px;
    }
}