.darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.modal {
    max-width: 800px;
    width: 100%;
    max-height: calc(100% - 60px);
    overflow-y: auto;
    background: white;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 25px 0;
    transform: translate(-50%, -50%);
}

.modal-content__container {
    height: auto;
    min-height: 140px;
}

.modalHeader {
    /* height: 50px; */
    text-align: center;
    /* overflow: hidden; */
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 15px;
}

.modal-heading {
    margin: 0;
    padding: 0 55px 15px 50px;
    color: #2c3e50;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}

.modalContent {
    padding: 0 40px 20px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
}

.modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
}

.actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 10px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: sticky;
    left: 92%;
    top: 0;
    align-self: flex-end;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
} */

.closeBtn svg {
    width: 20px;
    height: 20px;
}

.cta-button {
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.9rem;
    border: none;
    color: #fff;
    background: #EE060B;
    transition: all 0.2s ease;
}

.cta-button:hover {
    box-shadow: 0 10px 20px -10px rgb(238, 6, 11, 0.6);
    transform: translateY(-10px);
    background: #EE060B;
}

.cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
}

.cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
}

/* .add-book__form {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.modal__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.add-book-form__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-book-form__container .modal__section-image-container {
    margin-right: 40px;
}

.add-book__form .modal__section textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
}

.add-book__form .modal__section select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
}

.modal__section-left-align {
    align-items: flex-start;
}

.book-pages-section input {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 20%;
    text-align: center;
}

.upload-image-section span {
    text-align: left;
    margin: 0 auto 5px 0;
}

.upload-image-section input[type="file"] {
    visibility: hidden;
    height: 0;
}

.modal__section-content-container .modal__section.upload-image-section label {
    font-size: 14px;
    height: 32px;
    margin-bottom: 0;
}

.modal__section-image-container .modal__section {
    align-items: flex-start;
}

.modal__section-content-container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__section-content-container .modal__section {
    margin-bottom: 10px;
}

.modal__section-content-container .modal__section label {
    margin-bottom: 5px;
}

.modal__section-content-container .modal__section .dropdown {
    max-width: unset;
    margin-bottom: 10px;
}

.modal__delete-btn {
    position: absolute;
    left: 25px;
    top: 30px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    fill: #000;
    transition: fill ease-in-out 0.3s;
}

.modals__container {
    position: relative;
}

.modal.modal-sm {
    height: unset;
    z-index: 21;
}

.darkBG.modal-sm {
    z-index: 20;
}

.confirmation-dialog {
    color: #ff6700;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #ff6700;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    min-width: 350px;
}

.confirmation-dialog p {
    margin-bottom: 15px;
}

.confirmation-dialog .cta-btn {
    margin: 0;
}

.confirmation-dialog .cta-btn:first-of-type {
    margin-right: 20px;
}

.confirmation-dialog .button-container {
    justify-content: center;
}

.input-field.confirmation-dialog-shown.error input {
    border: 1px solid indianred;
}

.update-book__content label {
    display: block;
    color: #111;
    font-size: 16px;
    margin-bottom: 10px;
}

.update-book__content input {
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 12px 10px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
}

.add-book__form .error-message__container {
    margin: 0 auto 10px;
}

@media (max-width: 768px) {
    .modal {
        width: 90%;
        overflow: auto;
        padding: 30px 0;
    }



    .add-book-form__container {
        flex-direction: column;
    }

    .modal-content__container {
        overflow: auto;
        max-height: 85%;
    }

    .add-book-form__container .modal__section-image-container {
        margin-right: 0;
    }

    .modal__section-image-container .modal__section {
        align-items: center;
    }

    .modal__section-content-container .modal__section label.upload-btn {
        margin: 0 0 5px;
        width: 100%;
        height: 35px;
    }

    .modal__section-content-container .modal__section {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .modal-content__container {
        max-height: 75%;
    }

    .closeBtn {
        left: 85%;
    }
}