.weekly-dashboard {
    /* padding: 20px; */
    text-align: center;
}

.no-data__container {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-data__container .heading {
    margin-bottom: 40px;
}

.no-data__container .cta-btn {
    margin: 0;
}

.spotify-player__container {
    z-index: 2;
}

.spotify-player__container svg {
    position: absolute;
    right: 33px;
    top: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: fill ease 0.3s;
}

.spotify-player__container svg:hover {
    fill: #1DB954;
}

.spotify-player__container.open {
    max-width: 600px;
    width: 100%;
}

.spotify-iframe {
    max-width: 600px;
    margin: 10px auto;
    padding: 0 10px;
    border-radius: 12px;
    z-index: 10;
    position: relative;
}

@media (max-width: 768px) {
    .spotify-player__container svg {
        top: 20px;
    }

    .spotify-player__container {
        top: 120px;
    }
}