@import './variables/variables.scss';

.profile__container,
.chart__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.profile__container {
    margin-bottom: 30px;
    flex-direction: row;
    justify-content: space-between;
}

.profile__container h1 {
    margin-bottom: 20px;
}

.spinner__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.profile__content,
.chart__content {
    background-color: $white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    /* max-width: 500px; */
    box-shadow: 0 2px 4px $boxShadow;
    position: relative;
}

.profile__content {
    flex: 0 0 48%;
    margin-right: 20px;
    padding: 20px 15px;
    min-height: 394px;
    height: 100%;
}

.profile__content h1 {
    margin-bottom: 10px;
}

.profile__status {
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    font-size: 18px;
}

.profile__field {
    margin-bottom: 20px;
    word-break: break-all;
}

.profile__clickable {
    cursor: pointer;
    color: $red;
    margin-bottom: 3px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 16px;
}

.profile__clickable::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #c25061;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.profile__clickable:focus,
.profile__clickable:hover {
    outline: none;
    color: #c25061;
}

.profile__clickable:hover::before,
.profile__clickable:focus::before {
    transform: scaleX(1);
}

.profile__info {
    display: inline-block;
    color: #444444;
    font-size: 14px;
}

.profile__textarea,
.profile__input {
    width: 100%;
    padding: 6px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
    font-size: 14px;
}

.profile__textarea.hidden,
.profile__input.hidden {
    display: none;
}

.profile__profile-picture,
.profile__picture-container .change-picture__btn {
    position: absolute;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    right: 0;
    top: 0;
    transition: box-shadow 0.3s ease, opacity 0.3s ease;
    z-index: 0;
}

.profile__picture-container .change-picture__btn:focus {
    border: 1px solid $black;
}

.profile__picture-container .change-picture__btn:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0.5;
}

.profile__picture-container .change-picture__btn {
    position: absolute;
    width: 150px;
    height: 150px;
    z-index: 2;
    border-radius: 50%;
    right: 15px;
    top: 10px;
}

.profile__picture-container .change-picture__btn::before {
    content: 'Change Image';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    color: $black;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
    font-weight: 600;
}

.profile__picture-container .change-picture__btn:hover::before {
    opacity: 1;
}

.update__button {
    color: white;
    background: $red;
    border: none;
    padding: 8px 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 15px auto;
    font-size: 16px;
    transition: all 0.25s ease;
}

.update__button:hover {
    box-shadow: 0 10px 20px -10px rgba(238, 6, 11, 0.6);
    transform: translateY(-5px);
    background: $red;
}

.profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 170px;
}

.image-uploader {
    position: relative;
    width: 100%;
}

.upload-label {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    top: 52px;
}

.upload-label.hidden {
    display: none;
}

.file-input {
    display: none;
}

.profile-picture {
    position: absolute;
    right: 0;
    top: 10px;
}

.image-container {
    height: 150px;
    width: 150px;
    border: 2px dashed $black;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    display: none;
}

.editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zoom-control {
    margin-top: 20px;
}

/* .chart__content {
    min-width: 600px;
} */

.diagram__container {
    max-width: 1000px;
    width: 100%;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 2px 4px $boxShadow;
    border-radius: 10px;
    flex: 0 0 48%;
    position: relative;
}

.diagram__container p {
    text-align: center;
}

.range-calendar__container {
    justify-content: flex-start;
    margin-bottom: 20px;
}

.range-calendar__container .dropdown {
    margin-right: 25px;
    height: 50px;
    margin-bottom: 0;
    font-size: 0.9rem;
}


.range-calendar__container .dropdown-options {
    max-height: 190px;
}

@media (max-width: 1200px) {
    .profile__container {
        flex-direction: column;
    }

    .profile__content {
        margin-right: 0;
        max-width: 1000px;
    }

    .chart__content canvas {
        width: 100% !important;
        height: 100% !important;
    }

    .profile__content {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .chart__content {
        max-width: 500px;
        min-width: 300px;
        width: 100%;
    }

    .hide-x-axis-label {
        display: none;
    }

    .range-calendar__container {
        flex-direction: column;
        margin-bottom: 75px;
    }

    .range-calendar__container .dropdown {
        margin-right: 0;
        max-width: unset;
    }

    .range-calendar__container .react-calendar {
        max-width: unset;
        right: 0;
        left: 0;
    }

}


@media (max-width: 576px) {

    .profile__content {
        padding: 30px;
        max-width: 300px;
    }

    .profile__picture-container,
    .profile-picture {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }

    .profile__picture-container .change-picture__btn,
    .profile-picture {
        position: relative;
        top: 0;
    }

    .profile__container .modal {
        overflow: auto;
    }

    .profile__container canvas {
        width: 275px !important;
        height: 275px !important;
    }

    .profile__content h1 {
        font-size: 22px;
    }

    .profile__status {
        font-size: 16px;
    }
}