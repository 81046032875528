@import './variables/variables.scss';

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
}

.selected-option {
    background-color: $white;
    border: 1px solid $lightGrey;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    background-image:
        linear-gradient(45deg, transparent 50%, $black 50%),
        linear-gradient(135deg, $black 50%, transparent 50%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
}

.dropdown-options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-height: 114px;
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $lightGrey;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
    display: none;
    transition: max-height 0.3s ease-in-out;
}

.dropdown-option {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.dropdown-option:nth-child(even) {
    background-color: #f9f9f9;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.dropdown.open .dropdown-options {
    display: block;
}

.dropdown.open .selected-option {
    background-image:
        linear-gradient(45deg, $black 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $black 50%);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    outline: 0;
}