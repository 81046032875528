@import './variables/variables.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $font;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

textarea {
    resize: none;
}

html {
    position: relative;
    min-height: 100vh;
}

main {
    min-height: 100vh;
    padding: 0 15px 105px;
}

body {
    padding-top: 90px;
}

.cta-btn {
    display: block;
    padding: 5px 16px;
    border: 0px;
    border-radius: 32px;
    background-color: $mainColor;
    border: 1px solid $mainColor;
    height: 40px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin: auto;
    transition: all 0.2s ease;
    text-decoration: none;
}

.btn-sm {
    font-size: 14px;
}

.cta-btn:hover {
    color: $mainColor;
    background-color: #fff;
}

.cta-btn:hover .react-spinner-material {
    border-color: $mainColor$mainColor !important;
}

.cta-btn__alt {
    color: $mainColor;
    background-color: #fff;
}

.cta-btn__alt:hover {
    color: #fff;
    background-color: $mainColor;
}

.cancel-btn {
    margin-right: 10px;
}

.close-btn {
    cursor: pointer;
    border-radius: 8px;
    border: none;
    padding: 1px;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-btn svg {
    fill: $mainColor;
    font-size: 25px;

}

.close-btn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
}

.header {
    color: #111;
    padding: 2rem 1rem;
    text-align: center;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none !important;
}

.fw-600 {
    font-weight: 600;
}

.input-field.error input {
    border: 1px solid red;
}

.cta-btn[disabled] {
    background-color: $lightGrey;
    color: #666666;
    border-color: #666666;
    cursor: not-allowed;
}

.heading {
    font-size: 36px;
    margin-bottom: 10px;
    font-weight: 600;
}

@media (max-width: 768px) {
    body {
        padding-top: 110px;
    }
}