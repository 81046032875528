// Colors

$mainColor: #D92868;
$mainColor-dark: #B50637;
$white: #fff;
$lightGrey: #ccc;
$beigeBackground: #EDC9BF;
$black: #111;
$red: #EE060B;
$boxShadow: rgba(0, 0, 0, 0.1);
$green: #50C878;

//Fonts

$font: "Poppins", sans-serif;
$countdownFont: 'Black Ops One';

// Border
$border: 1px solid #ccc;