@import './variables/variables.scss';

body {
    background-color: $white;
}

.login-signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.wrapper {
    height: 100vh;
    width: 50%;
    padding: 50px;

}

.verification-email-sent-page .wrapper {
    width: 100%;
}

.verification-email-sent-page .mask {
    z-index: -1;
}

.wrapper:first-of-type {
    padding: 80px 30px;

}

.login-form__container {
    margin-right: 30px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form__container form {
    position: relative;
    border-radius: 20px;
    background: $white;
    box-shadow: 0px 19px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 85px 65px;
    max-width: 570px;
    width: 100%;

}

.login-form__container .form__message {
    color: #797676;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.48px;
    margin-bottom: 10px;
}

.login-form__container h1 {
    color: $black;
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 28px;
}

.login-form__container .form__forgot-pass {
    font-size: 14px;
    position: absolute;
    text-decoration: underline;
    top: 0;
    right: 0;
    font-size: 14px;
    font-weight: 300;
}

.login-form__container .form__forgot-pass a {
    color: #797676;
}

.login-form__container .form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 19px;
    position: relative;
}

.login-form__container .form__group input {
    border-radius: 4px;
    background: $white;
    border: $border;
    padding: 12px 10px;
    color: $black;
    font-size: 14px;
    font-weight: 400;
}

.login-form__container .form__group label {
    color: $black;
    font-size: 16px;
    font-weight: 300;
}

.form__error {
    color: $mainColor;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    border: 1px solid $mainColor;
    background-color: $white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.btn--cta {
    border-radius: 25px;
    background-color: $mainColor;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid $mainColor;
    padding: 10px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 38px;
}

.btn--cta:hover {
    cursor: pointer;
    background-color: $white;
    color: $mainColor;
}

.form-switch {
    color: #797676;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
    text-align: center;
    margin-bottom: 10px;
}

.form-switch a {
    color: $mainColor;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
}

.form-switch a:hover {
    text-decoration: underline;
    opacity: 0.75;
}

.image__container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.image__container img {
    max-width: 100%;
    height: auto;
}

.mask {
    position: absolute;
    right: 0;
    top: 0;
    /* height: calc(100% + 40px); */
    width: 40%;
    background-color: $beigeBackground;
}

.google-auth-btn__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.recaptcha__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-requirements {
    margin-top: 8px;
}

.password-requirements p {
    margin: 6px 0;
    font-size: 14px;
}

.password-requirements .fulfilled::before {
    content: '✅ ';
    color: $green;
}

.password-requirements .unfulfilled::before {
    content: '❌ ';
    color: $red;
}

@media (max-width: 1050px) {
    .wrapper {
        padding: 25px;
    }

    .image__container img {
        width: 400px;
    }

    .wrapper.login-form__container {
        width: 60%;
    }

    .wrapper:last-of-type {
        width: 40%;
    }

    .login-form__container form {
        max-width: 600px;
        padding: 80px 50px;
    }

    .image__container img {
        width: 350px;
    }
}

@media (max-width: 768px) {
    .mask {
        background-color: $beigeBackground;
    }

    .wrapper.login-form__container {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .wrapper:last-of-type {
        display: none;
    }

    .login-signup-page {
        background-color: $beigeBackground;
    }
}

@media (max-width: 576px) {
    .mask {
        top: 0;
        height: 100%;
    }

    .login-form__container form {
        padding: 30px 25px;
        width: 100%;
    }
}