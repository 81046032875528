@import './variables/variables.scss';

.books__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.book {
    max-height: 345px;
    height: 100%;
    /* margin-right: 20px; */
    /* margin-bottom: 20px; */
    width: 100%;
}

.book__button {
    height: 345px;
}

.book__image {
    max-height: 170px;
    height: 100%;
    object-fit: contain !important;
    object-position: top;
}

.book__title {
    white-space: nowrap;
    max-width: 310px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.book__authors {
    white-space: nowrap;
    max-width: 310px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.books-list__container .book__title {
    color: $black;
}

.book__container {
    flex: 0 0 23%;
    margin: 10px;
    text-align: center;
    min-width: 200px;
    margin-bottom: 20px;
}

.book__description {
    white-space: nowrap;
    max-width: 310px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error-message__container {
    color: $mainColor;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    border: 1px solid $mainColor;
    background-color: $white;
    padding: 10px 40px;
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    min-width: 250px;
}

.success-message__container {
    color: $green;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    border: 1px solid $green;
    background-color: $white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    transition: opacity 0.5s ease-out;
    opacity: 1;
    position: relative;
    min-width: 350px;
}

.fade-in-out {
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 1025px) {
    .book__container {
        flex: 0 0 30%;
    }
}

@media (max-width: 768px) {
    .book__container {
        flex: 0 0 45%;
    }
}

@media (max-width: 576px) {
    .book__container {
        flex: 0 0 100%;
        margin: 10px 0;
        padding: 10px;
    }

    .book__container .book {
        max-width: unset;
    }
}