@import './variables/variables.scss';

.calendar-container {
    position: relative;
    width: 100%;
}

.range-calendar__container .react-calendar {
    position: absolute;
    width: 100%;
    max-width: 280px;
    right: 20px;
    margin: 0 auto;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 10;
}

.calendar-container.calendar-hidden .react-calendar {
    z-index: 0;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.input-field__container {
    /* flex: 1 1 calc(50% - 10px); */
    margin: 0 10px 30px 0;
}

.input-field__container .weekday {
    margin-bottom: 5px;
}

.input-field {
    border-radius: 5px;
}

.input-field input {
    padding: 5px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
}

.input-field.error input {
    border-color: $red;
}

.input-field label,
.input-field__container label {
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    font-size: 1.1rem;

}

.input-fields {
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-between; */
}



.react-calendar__viewContainer {
    opacity: 1;
    transition: all 0.3s ease;
}

.calendar-hidden .react-calendar__viewContainer {
    height: 0;
    opacity: 0;
}

.react-calendar__navigation {
    transition: all 0.3s ease;
}

.calendar-hidden .react-calendar__navigation {
    height: 30px;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .input-field {
        flex: 1 1 calc(100% - 20px);
    }

    .input-fields {
        align-items: center;
        justify-content: center;
    }

}