@import './variables/variables.scss';

.books__library {
    padding: 20px 20px 105px;
}

.books__library .book__carousel .book__button {
    padding: 0 20px;
}

.shelf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 0;
    position: relative;
}

.shelf-header::after {
    content: '';
    position: absolute;
    width: calc(100% + 30px);
    left: -15px;
    bottom: 0;
    height: 1px;
    background-color: #ddd;
}

.shelf-title {
    margin-right: 20px;
    font-size: 24px;
    margin: 0;
}

.shelf-header .cta-btn {
    margin-left: 20px;
}

.book__image {
    max-height: 220px;
}

.book__carousel {
    width: 100%;
    max-width: 600px;
}

.library__title {
    text-transform: capitalize;
}

.books__library .books__container {
    margin-bottom: 40px;
}

.books__library .book-colorful {
    padding: 0;
}

.books__library .book-colorful__image {
    transform: translate(0, -25px);
    width: 100%;
    max-width: 165px;
    height: 100%;
    max-height: 250px;
}

.books-colorful__container .book__button {
    display: flex;
    padding: 30px;
}

.no-books__message-container {
    margin-bottom: 50px;
    text-align: center;
}

.book-colorful .book__description {
    max-width: 310px;
    width: 100%;
    max-height: 80px;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
}

.book-colorful .book__action-area {
    flex-direction: column;
    justify-content: center;
}

.book-colorful .book__title.book__title-outline {
    color: $black;
    text-shadow: 9px 0px 7px $white, -5px 1px 7px $white;
}

.book__button {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.book__details {
    margin-left: 15px;
    margin-right: auto;
    width: 100%;
}

.book__title {
    color: $white;
    font-weight: 600;
    font-size: 1.3rem;
}

.edit-shelf__icon {
    position: absolute;
    display: flex;
    align-items: center;
    right: 20px;
    top: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 7;
}

.edit-shelf__icon svg {
    width: 25px;
    height: 25px;
    fill: $white;
    transition: fill 0.3s ease;
}

.edit-shelf__icon svg:hover {
    fill: $lightGrey;
}

.edit-shelf__container {
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 7;
}

.edit-shelf__container button {
    background-color: $white;
    padding: 10px;
    border: 1px solid $lightGrey;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px $boxShadow;
    cursor: pointer;
}

.edit-shelf__container button:hover {
    background-color: #f0f0f0;
}

@media (max-width: 576px) {

    .books__library .books__container.currently-reading__container {
        height: 525px;
        padding: 10px 0;
        margin-bottom: 30px;
    }

    .books__library .currently-reading__container .book__authors {
        display: none;
    }

    .currently-reading__container .book {
        height: 100%;
        max-height: unset;
    }

    .currently-reading__container .book__button {
        flex-direction: column;
    }

    .book__image {
        margin-bottom: 10px;
    }

    .book__title {
        max-width: unset;
        text-align: center;
        margin-bottom: 10px;
    }

    .books__library .swiper-pagination {
        bottom: 30px;
    }
}