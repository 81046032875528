@import './variables/variables.scss';


.nav__container {
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $beigeBackground;
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 8;
}

.nav__container--visible {
    top: 0;
    transition: top 0.4s ease-out;
}

.nav__container--hidden {
    top: -90px;
    transition: top 0.4s ease-out;
}

.nav__container.nav__fixed {
    position: fixed;
    top: 63px;
    width: 100%;
    transform: translateY(-100%);
    box-shadow: 0 4px 8px $boxShadow;
    z-index: 14;
}

.nav__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav__username {
    font-weight: bold;
    font-size: 18px;
    margin-right: 15px;
}

.nav__item {
    margin-right: 25px;
}

.nav__link {
    color: #3F6153;
    text-decoration: none;
}

.nav__link:hover {
    color: #5E81AC;
}

.nav__link.active {
    color: #B50637;
    font-weight: 600;
}

.nav__login-register .nav__link.active {
    font-weight: 500;
}

.nav__login-register {
    padding: 10px 0;
}

.nav__login-register .nav__item a {
    padding: 10px;
    background-color: $mainColor;
    color: #fff;
    border-radius: 10px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.nav__login-register .nav__item a:hover {
    color: $mainColor;
    background-color: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.nav__login-register .nav__item button {
    padding: 10px;
    color: $mainColor;
    background-color: #fff;
    border: 1px solid $mainColor;
    border-radius: 10px;
    font-size: 16px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.nav__login-register .nav__item button:hover {
    background-color: $mainColor;
    color: #fff;
}

.nav__profile-picture {
    margin-right: 15px;
    width: 50px;
    height: 50px;
}

.nav__profile-picture img {
    border-radius: 50%;
    transition: box-shadow 0.3s ease;

}

.nav__profile-picture img:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

}

.nav__burger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.burger__line {
    display: block;
    width: 40px;
    height: 6px;
    background-color: #4E6B5D;
    margin: 6px auto;
    transition: background-color 0.3s ease;
}

.nav__burger.open .burger__line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
}

.nav__burger.open .burger__line:nth-child(2) {
    opacity: 0;
}

.nav__burger.open .burger__line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}

@media (max-width: 768px) {

    .nav__burger {
        display: block;
    }

    .nav__container {
        padding: 20px 25px;
        width: 100%;
        height: 110px;
    }

    .nav__container.nav__fixed {
        top: 110px;
    }

    .nav__list {
        display: none;
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        background-color: $beigeBackground;
        box-shadow: 0px 2px 4px $boxShadow;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav__main .nav__list {
        height: calc(100vh - 90px);
        z-index: 10;
        /* visibility: hidden; */
        display: flex;
        align-items: flex-start;
        overflow-x: hidden;
        transform: translateX(-100%);
        transition: -webkit-transform 0.3s ease, transform 0.3s ease;
    }

    .nav__login-register .nav__list {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: flex-end;
        background: no-repeat;
        box-shadow: none;
        top: 5px;
        width: 50%;
        right: 0;
        left: auto;
        transform: translateX(0);
        position: static;
        width: 100%;
        padding: 0;
    }

    .nav--open.nav__main .nav__list {
        width: 60%;
        /* visibility: visible; */
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        padding-left: 50px;
    }

    .nav__login-register.registered .nav__list {
        padding: 0;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }

    .nav__login-register.registered .nav__profile-picture {
        padding: 0;
        background-color: transparent;
        color: transparent;
    }

    .nav__main .nav__list .nav__item {
        margin-bottom: 50px;
    }

    .nav__main .nav__list .nav__link {
        font-size: 1.8rem;
        text-align: left;
    }

    .nav__container--hidden {
        top: -110px;
    }
}

@media (max-width: 576px) {

    .search__container {
        padding: 25px 10px;
    }

    .nav--open.nav__main .nav__list {
        width: 100%;
    }

    .nav__login-register .nav__list .nav__item {
        margin-right: 5px;
    }

    .nav__login-register .nav__list .nav__item:last-of-type {
        margin-right: 0;
    }
}