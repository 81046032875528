.verification-success__error {
    background-color: #ffcccc;
    padding: 20px;
    border: 1px solid #ff3333;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.2);
    text-align: center;
    margin: 20px auto;
    max-width: 550px;
}

.verification-success__error h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.verification-success__error p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ff3333;
}

.verification-success__container {
    background-color: #ccffcc;
    padding: 20px;
    border: 1px solid #33ff33;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.2);
    text-align: center;
    margin: 20px auto;
    max-width: 550px;
}

.verification-success__message {
    margin-bottom: 20px;
}

.verification-success__title {
    font-size: 24px;
    margin-bottom: 10px;
}

.verification-success__container svg {
    width: 80px;
    height: 80px;
    fill: #177f17;
}

.verification-success__error svg {
    width: 80px;
    height: 80px;
    fill: #ff3333;
}

.verification-error__contact {
    font-size: 16px;
}

.verification-error__contact a {
    color: #007bff;
    text-decoration: none;
}

.verification-error__contact a:hover {
    text-decoration: underline;
}

.verification-success__login-link {
    font-size: 16px;
    margin-top: 20px;
}

.verification-success__login-link a {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}

.verification-success__login-link a:hover {
    text-decoration: none;
}