@import './variables/variables.scss';

.book {
    max-width: 600px;
    position: relative;
    /* padding: 0 20px; */
}

.blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: blur(10px);
    z-index: -1;
}

.book-font__outline {
    text-shadow: 3px 3px 5px $black, 3px 3px 5px $black;
}

.details__additional-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.book__all-pages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 20px;
}

.book__all-pages p:first-child {
    font-size: 14px;
    color: $white;
    margin-bottom: 10px;
}

.book__all-pages svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.details__additional-info .book__category {
    max-width: 130px;
    width: 100%;
    margin-right: 0;
    font-size: 12px;
    word-wrap: break-word;
}

.book__details .book__see-more {
    color: $black;
    font-size: 14px;
    text-decoration: none;
    position: relative;
}

.book__details .book__see-more::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background-color: $black;
    transition: width 0.3s ease;
}

.book__details .book__see-more:hover::after {
    color: $black;
    width: 100%;
}


.book__details .book__see-more svg {
    margin-left: 5px;
}

.book__progress {
    margin-bottom: 10px;
}

.details__additional-info .book__action-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.details__additional-info .book__action-area p {
    font-size: 14px;
    color: $white;
    margin-bottom: 10px;
}

.books-colorful__container .book-colorful {
    position: relative;
}

@media (max-width: 1200px) {
    .books-colorful__container .book__button {
        flex-direction: column;
        height: 500px;
    }

    .books__library .book-colorful__image {
        transform: translate(0, 0);
    }

}

@media (max-width: 807px) {
    .books-colorful__container .book-colorful {
        margin-right: 0 !important;
    }

    .books-colorful__container .book__button {
        flex-direction: row;
        width: 100%;
        height: 345px;
        padding: 0;
    }

    .books__library .books-colorful__container .book-colorful__image {
        margin-right: 20px;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .books__library .book-colorful__image {
        transform: translate(0, 10px);
    }

    .book-colorful__info {
        max-width: unset;
    }

    .books__library .books-colorful__container .book-colorful {
        margin-right: 0;
    }
}